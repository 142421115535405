
window.addEventListener('load', () => {
  const cookieManager = document.getElementById('cookie-manager')
  const landing = document.getElementById('cookie-manager-landing')
  const form = document.getElementById('cookie-manager-form')
  const acceptAllBtn = document.getElementsByClassName('cookie-manager-btn-accept-all')
  const showFormBtn = document.getElementById('cookie-manager-btn-show-form')
  const collapseElArray = document.querySelectorAll("*[data-toggle='cookie-collapse']")

  const openLanding = () => {
    if (form) form.style.display = 'none'
    if (landing) landing.style.display = null
  }

  if (cookieManager) {
    cookieManager.style.display = null
    openLanding()
  }

  const acceptAll = () => {
    console.log('clicking')
    openForm()
    form.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = true
    })
    form.submit()
  }

  const openForm = () => {
    if (form) form.style.display = null
    if (landing) landing.style.display = 'none'
  }

  if (showFormBtn) {
    showFormBtn.addEventListener('click', openForm)
  }

  for (let btn of acceptAllBtn) {
    btn.addEventListener('click', acceptAll)
  }

  for (const collapseEl of collapseElArray) {
    collapseEl.addEventListener('click', function (event) {
      event.preventDefault()
      let targetId = this.getAttribute('data-target')
      let targetEl = document.getElementById(targetId)
      let contentEl = targetEl ? targetEl.children[0] : null
      if (contentEl) {
        if (targetEl.classList.contains('show')) {
          targetEl.style.height = contentEl.offsetHeight + 'px';
          targetEl.classList.remove('show');
          setTimeout(function () {
            targetEl.style = '';
          }, 100);
        } else {
          targetEl.style.height = '0px';
          targetEl.style.height = contentEl.offsetHeight + 'px';
          setTimeout(function () {
            targetEl.classList.add('show');
            targetEl.style = '';
          }, 400);
        }
        let collapseButtons = document.querySelectorAll("*[data-target='" + targetId + "']")
        for (let collapseButton of collapseButtons) {
          collapseButton.classList.toggle('d-none');
        }
      }
    })
  }
})
